import React from "react";

const Footer = () => {
  return (
    <footer>
      <div class="copyright">All rights reserved. &copy; Copyright 2024.</div>
    </footer>
  );
};

export default Footer;
